import React, { useState } from 'react';
import Acknowledgment from './Acknowledgment';

const CreatePool = ({ onCreatePool, onClose, onAcknowledgment }) => {
  const [poolName, setPoolName] = useState('');
  const [swapNumber, setSwapNumber] = useState('');
  const [forwardType, setForwardType] = useState('Flow');
  const [forwardCallsTo, setForwardCallsTo] = useState('liberty-leadgent-inbound');
  const [currentLimit, setCurrentLimit] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [acknowledgment, setAcknowledgment] = useState(null);
  const [selectedCountryCode, setSelectedCountryCode] = useState('');
  const [selectedPhoneCountryCode, setSelectedPhoneCountryCode] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [phoneNumberCC, setPhoneNumberCC] = useState('');
  const [mandatoryFieldMissing, setMandatoryFieldMissing] = useState(false);

  const countryCodes = [
    { name: 'United States (+1)', code: '+1' }
  ];

  const handleSubmit = () => {
    setIsLoading(true);

    if (!poolName || !swapNumber || !selectedCountryCode ||
      (forwardType !== 'Flow' && (!selectedPhoneCountryCode || !phoneNumber)) ||
      !currentLimit) {
      setMandatoryFieldMissing(true);
      setIsLoading(false);
      return;
    }

    setMandatoryFieldMissing(false);

    const payload = {
      DynamicPoolName: poolName,
      SwapNumber: swapNumber,
      SwapNumberCC: selectedCountryCode,
      ForwardType: forwardType,
      CurrentLimit: currentLimit,
    };

    if (forwardType === 'PhoneNumber') {
      payload.ForwardCallsTo = {
        PhoneNumber: phoneNumber,
        PhoneNumberCC: selectedPhoneCountryCode,
      };
    } else if (forwardType === 'Flow') {
      payload.ForwardCallsTo = {
        FlowID: forwardCallsTo,
      };
    } else {
      setIsLoading(false);
      // setAcknowledgment({
      //   status: 'warning',
      //   message: 'Please provide both Phone Number and Country Code.',
      // });
      return; // Abort the submission
    }

    fetch('https://v2api.leadgen.pennysaver.in/create_dynamic_pool', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    })
      .then(async (response) => {
        setIsLoading(false);
        const responseData = await response.json();

        if (response.status === 201) {
          console.log(response.message);
          setAcknowledgment({
            status: 'success',
            message: responseData.message,
          });

          onAcknowledgment({
            status: 'success',
            message: responseData.message,
            maxLimit: responseData.maxLimit,
          });
          onCreatePool();
        } else if (response.status === 409) {
          console.log(response.message);
          setAcknowledgment({
            status: 'warning',
            message: responseData.message,
          });
        } else if (response.status === 500) {
          console.log(response.message);
          setAcknowledgment({
            status: 'warning',
            message: responseData.message,
          });
        }
          else if (response.status === 400) {
            console.log(response.message);
            setAcknowledgment({
              status: 'warning',
              message: responseData.message,
            });
        } else {
          setAcknowledgment({
            status: 'warning',
            message: 'An error occurred while creating the number',
          });
        }
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
        onAcknowledgment({
          status: 'warning',
          message: 'An error occurred while communicating with the server.',
        });
      });
  };

  const handleAcknowledgmentClose = () => {
    setAcknowledgment(null);
  };

  return (
    <>
      <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
        <div className="bg-white rounded shadow p-8 w-96">
          <h2 className="text-lg font-bold mb-6">Create Pool</h2>
          <form>
            <div className="mb-4">
              <label htmlFor="poolName" className="block text-sm font-medium text-gray-700">
                Pool Name
              </label>
              <input
                type="text"
                id="poolName"
                className="mt-1 focus:ring focus:border-blue-500 block w-full shadow-sm sm:text-sm border border-gray-300 rounded-md"
                value={poolName}
                onChange={(e) => setPoolName(e.target.value)}
              />
            </div>

            <div className="mb-4">
              <label htmlFor="swapNumberCC" className="block text-sm font-medium text-gray-700">
                Swap Number(Country Code)
              </label>
              <select
                id="swapNumberCC"
                className="mt-1 focus:ring focus:border-blue-500 block w-full shadow-sm sm:text-sm border border-gray-300 rounded-md"
                value={selectedCountryCode}
                onChange={(e) => setSelectedCountryCode(e.target.value)}
              >
                <option value="">Select Country Code</option>
                {countryCodes.map((country) => (
                  <option key={country.code} value={country.code}>
                    {country.name}
                  </option>
                ))}
              </select>
            </div>

            <div className="mb-4">
              <label htmlFor="swapNumber" className="block text-sm font-medium text-gray-700">
                Swap Number
              </label>
              <input
                type="text"
                id="swapNumber"
                className="mt-1 focus:ring focus:border-blue-500 block w-full shadow-sm sm:text-sm border border-gray-300 rounded-md"
                value={swapNumber}
                onChange={(e) => setSwapNumber(e.target.value)}
              />
            </div>

            <div className="mb-4">
              <label htmlFor="forwardType" className="block text-sm font-medium text-gray-700">
                Forward Type
              </label>
              <select
                id="forwardType"
                className="mt-1 focus:ring focus:border-blue-500 block w-full shadow-sm sm:text-sm border border-gray-300 rounded-md"
                value={forwardType}
                onChange={(e) => setForwardType(e.target.value)}
              >
                <option value="Flow">Amazon Connect Inbound Flow</option>
                <option value="PhoneNumber">Phone Number</option>
              </select>
            </div>

            {forwardType !== 'Flow' && (
              <>
                <div className="mb-4">
                  <label htmlFor="phoneNumberCC" className="block text-sm font-medium text-gray-700">
                    Phone Number(Country Code)
                  </label>
                  <select
                    id="phoneNumberCC"
                    className="mt-1 focus:ring focus:border-blue-500 block w-full shadow-sm sm:text-sm border border-gray-300 rounded-md"
                    value={selectedPhoneCountryCode}
                    onChange={(e) => setSelectedPhoneCountryCode(e.target.value)}
                  >
                    <option value="">Select Country Code</option>
                    {countryCodes.map((country) => (
                      <option key={country.code} value={country.code}>
                        {country.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="mb-4">
                  <label htmlFor="phoneNumber" className="block text-sm font-medium text-gray-700">
                    Forward Calls To Phone Number
                  </label>
                  <input
                    type="text"
                    id="phoneNumber"
                    className="mt-1 focus:ring focus:border-blue-500 block w-full shadow-sm sm:text-sm border border-gray-300 rounded-md"
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                  />
                </div>
              </>
            )}

            <div className="mb-4">
              <label htmlFor="currentLimit" className="block text-sm font-medium text-gray-700">
                Tracking Number Limit
              </label>
              <input
                type="number"
                id="currentLimit"
                className="mt-1 focus:ring focus:border-blue-500 block w-full shadow-sm sm:text-sm border border-gray-300 rounded-md"
                value={currentLimit}
                onChange={(e) => setCurrentLimit(e.target.value)}
              />
            </div>
            
            {mandatoryFieldMissing && (
              <p className="text-red-500 text-sm mb-4">
                Please fill in all mandatory fields before submitting.
              </p>
            )}

            <div className="flex justify-end">
              <button
                type="button"
                className="mr-2 py-2 px-4 bg-red-500 text-white rounded hover:bg-red-600 focus:outline-none focus:bg-red-600"
                onClick={onClose}
              >
                Cancel
              </button>
              <button
                type="button"
                className="py-2 px-4 bg-blue-500 text-white rounded hover:bg-blue-600 focus:outline-none focus:bg-blue-600"
                onClick={handleSubmit}
                disabled={isLoading}
              >
                {isLoading ? 'Creating...' : 'Create Pool'}
              </button>
            </div>
          </form>
        </div>
      </div>

      {acknowledgment && (
        <Acknowledgment acknowledgment={acknowledgment} onClose={handleAcknowledgmentClose} />
      )}
    </>
  );
};

export default CreatePool;
