import React, { useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import Table from './StaticNumberPage';
import DynamicPool from './DynamicPool';
import DynamicPoolNumbers from './DynamicPoolNumbers';
import Reports from './ReportsPage';
import { useHistory } from 'react-router-dom';
import { FaCog } from 'react-icons/fa';
import Acknowledgment from './Acknowledgment';
import QuicksightPage from './QuickSightPage';

const Dashboard = ({ onLogout }) => {
  const history = useHistory();
  const [activeTab, setActiveTab] = useState(localStorage.getItem('activeTab') || 'numbers');
  const [acknowledgment, setAcknowledgment] = useState('');
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [selectedPool, setSelectedPool] = useState(null);

  useEffect(() => {
    if (isSettingsOpen) {
      document.addEventListener('click', handleOutsideClick);
    }

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [isSettingsOpen, history]);

  useEffect(() => {
    localStorage.setItem('activeTab', activeTab);
  }, [activeTab]);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const handlePoolSelect = (poolName) => {
    setSelectedPool(poolName);
    setActiveTab('dynamicNumbers'); 
  };

  const handleAcknowledgmentClose = () => {
    setAcknowledgment('');
    history.push('/dashboard');
  };

  const handleSignOut = async () => {
    await Auth.signOut();
    onLogout();
    localStorage.removeItem('authenticated');
    history.push('/');
  };

  const toggleSettings = () => {
    setIsSettingsOpen(!isSettingsOpen);
  };

  const handleOutsideClick = (event) => {
    if (!event.target.closest('.settings-btn')) {
      setIsSettingsOpen(false);
    }
  };

  return (
    <div className="container mx-auto mt-8">
      <div className="flex justify-between mb-4">
        <div>
          <button
            className={`tab-btn ${activeTab === 'numbers' ? 'active' : ''}`}
            onClick={() => handleTabChange('numbers')}
          >
            Static Numbers
          </button>
          <button
            className={`tab-btn ${activeTab === 'dynamicNumbers' ? 'active' : ''}`}
            onClick={() => handleTabChange('dynamicNumbers')}
          >
            Dynamic Pools
          </button>
          {/* <button
            className={`tab-btn ${activeTab === 'report' ? 'active' : ''}`}
            onClick={() => handleTabChange('report')}
          >
            Report
          </button> */}
          <button
            className={`tab-btn ${activeTab === 'analytics' ? 'active' : ''}`}
            onClick={() => handleTabChange('analytics')}
          >
            Analytics
          </button>
        </div>
        <div className="relative">
          <button className="tab-btn settings-btn" onClick={toggleSettings}>
            <FaCog className="w-6 h-6" />
          </button>
          {isSettingsOpen && (
            <div className="absolute right-0 mt-2 py-2 bg-white border border-gray-300 rounded shadow">
              <button
                className="px-4 py-2 block text-left hover:bg-gray-100 w-full"
                onClick={handleSignOut}
              >
                Logout
              </button>
            </div>
          )}
        </div>
      </div>
      {activeTab === 'numbers' && (
        <div className="p-4 bg-white rounded shadow mb-4">
          <Table setAcknowledgment={setAcknowledgment} />
        </div>
      )}
      {activeTab === 'dynamicNumbers' && (
        <div className="p-4 bg-white rounded shadow mb-4">
          {selectedPool ? (
            <DynamicPoolNumbers selectedPool={selectedPool} /> 
          ) : (
            <DynamicPool onPoolSelect={handlePoolSelect} /> 
          )}
        </div>
      )}
      {/* {activeTab === 'report' && (
        <div className="p-4 bg-white rounded shadow">
          <Reports />
        </div>
      )} */}
      {activeTab === 'analytics' && (
        <div className="p-4 bg-white rounded shadow">
          <QuicksightPage />
        </div>
      )}
      {acknowledgment && (
        <Acknowledgment
          onClose={handleAcknowledgmentClose}
          acknowledgment={acknowledgment}
        />
      )}
      <style>
        {`
          .tab-btn {
            padding: 0.5rem 1rem;
            background-color: transparent;
            border: none;
            cursor: pointer;
            font-size: 1rem;
            font-weight: bold;
            text-decoration: none;
            transition: all 0.3s ease;
            margin: 0 0.5rem;
          }
        
          .tab-btn.active {
            color: #0000FF;
            position: relative;
          }
        
          .tab-btn.active::after {
            content: '';
            position: absolute;
            bottom: -3px;
            left: 50%;
            transform: translateX(-50%);
            width: 40%;
            height: 2px;
            background-color: #0000FF;
          }
        `}
      </style>
    </div>
  );
};

export default Dashboard;
