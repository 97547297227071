import React from 'react';
import { FaCheckCircle, FaExclamationTriangle } from 'react-icons/fa';

const Acknowledgment = ({ acknowledgment, onClose }) => {
  const handleAcknowledgmentClose = () => {
    onClose(); 

    if (acknowledgment && acknowledgment.status === 'success') {
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
  };

  const isSuccess = acknowledgment && acknowledgment.status === 'success';
  const isWarning = acknowledgment && acknowledgment.status === 'warning';

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white rounded shadow p-8 w-96">
        {isWarning && (
          <div className="flex items-center mb-4">
            <FaExclamationTriangle className="text-red-600 mr-2" size={20} />
            <h2 className="text-lg font-bold">Warning</h2>
          </div>
        )}
        {isSuccess && (
          <div className="flex items-center mb-4">
            <FaCheckCircle className="text-green-600 mr-2" size={20} />
            <h2 className="text-lg font-bold">Success</h2>
          </div>
        )}
        <p>{acknowledgment && acknowledgment.message}</p>
        {(isSuccess || isWarning) && (
          <button
            type="button"
            className={`mt-4 py-2 px-4 ${isSuccess ? 'bg-blue-500' : 'bg-red-500'} text-white rounded hover:${
              isSuccess ? 'bg-blue-600' : 'bg-red-600'
            } focus:outline-none focus:bg-${isSuccess ? 'blue' : 'red'}-600`}
            onClick={handleAcknowledgmentClose}
          >
            OK
          </button>
        )}
      </div>
    </div>
  );
};

export default Acknowledgment;
