import React, { useEffect, useState } from 'react';
import { FaSyncAlt, FaSearch } from 'react-icons/fa';
import Pagination from './Pagination';
import CreatePool from './CreatePool';
import Acknowledgment from './Acknowledgment';

const DynamicPool = ({ onPoolSelect }) => {

  const [dynamicPools, setDynamicPools] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [isCreatePoolOpen, setIsCreatePoolOpen] = useState(false);
  const [isHovering, setIsHovering] = useState(null);
  const [maxLimit, setMaxLimit] = useState('');
  const [acknowledgment, setAcknowledgment] = useState(null);

  useEffect(() => {

    const fetchDynamicPools = async () => {
      try {
        const response = await fetch('https://v2api.leadgen.pennysaver.in/get_phno');
        const data = await response.json();

        const dynamicPoolsData = Object.keys(data.dynamicNumbers).map((poolName) => {
          const dynamicPoolData = data.dynamicNumbers[poolName];
          return {
            poolName: poolName,
            swapNumber: dynamicPoolData.swap_number_cc + dynamicPoolData.swap_number,
            forwardCallsTo:
              dynamicPoolData.forward_type === 'Flow'
                ? dynamicPoolData.forward_flow
                : dynamicPoolData.forward_phone_number_cc + dynamicPoolData.forward_phone_number,
            currentLimit: dynamicPoolData.current_limit,
            maxLimit: data.maxLimit,
            status: dynamicPoolData.phno_status ? 'Active' : 'Inactive',
          };
        });

        setDynamicPools(dynamicPoolsData);
        setIsLoading(false);
        setMaxLimit(data.maxLimit);
      } catch (error) {
        console.log('Error fetching dynamic pools:', error);
        setIsLoading(false);
      }
    };

    fetchDynamicPools();
  }, []);

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
    setCurrentPage(1);
  };

  const handleRefresh = () => {
    setIsLoading(true);

    fetch('https://v2api.leadgen.pennysaver.in/get_phno')
      .then((response) => {
        if (!response.ok) {
          throw new Error('Failed to fetch dynamic pools');
        }
        return response.json();
      })
      .then((data) => {
        setIsLoading(false);
      })
      .catch((error) => {
        console.log('Error fetching dynamic pools:', error);
        setIsLoading(false);
      });
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const filteredDynamicPools = dynamicPools.filter((pool) =>
    pool.poolName.toLowerCase().includes(searchQuery.toLowerCase())
  );
  const currentDynamicPools = filteredDynamicPools.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleCreatePoolClick = () => {
    setIsCreatePoolOpen(true);
  };

  const handleCreatePoolClose = () => {
    setIsCreatePoolOpen(false);
  };

  const handleStatusToggle = (index) => {
    console.log('Toggle status for pool at index:', index);
  };

  const handleRowClick = (poolName) => {
    onPoolSelect(poolName);
  };
  const onCreatePool = () => {

  };

  const handleAcknowledgment = (acknowledgmentData) => {

    setAcknowledgment(acknowledgmentData);
  };

  const handleAcknowledgmentClose = () => {
    setAcknowledgment(null);
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="flex justify-between items-center mb-8">
        <div className="flex items-center">
          <div className="mr-4">
            <span className="text-gray-600 text-sm font-semibold">Max Limit:</span>
            <span className="text-red-900 text-2xl font-bold ml-2">{maxLimit}</span>
          </div>
        </div>
        <div className="flex items-center">
          <div className="relative">
            <input
              type="text"
              value={searchQuery}
              onChange={handleSearch}
              placeholder="Pool Name"
              className="px-4 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
            />
            <FaSearch className="absolute top-1/2 right-3 transform -translate-y-1/2 text-gray-400" />
          </div>
          <button
            className="ml-4 bg-gray-200 text-gray-600 rounded hover:bg-gray-300 p-2 focus:outline-none"
            onClick={handleRefresh}
          >
            <FaSyncAlt className="w-4 h-4" />
          </button>
          <button
            className="ml-2 bg-blue-500 text-white rounded hover:bg-blue-600 p-2 focus:outline-none"
            onClick={handleCreatePoolClick}
          >
            Create Pool
          </button>
        </div>
      </div>

      {isLoading ? (
        <div className="pinwheel">
          <div className="pinwheel__line"></div>
          <div className="pinwheel__line"></div>
          <div className="pinwheel__line"></div>
          <div className="pinwheel__line"></div>
          <div className="pinwheel__line"></div>
          <div className="pinwheel__line"></div>
        </div>
      ) : (
        <div>
          <table className="min-w-full bg-white border border-gray-200">
            <thead>
              <tr>
                <th className="py-2 px-4 text-left border-b">Dynamic Pool Name</th>
                <th className="py-2 px-4 text-left border-b">Swap Number</th>
                <th className="py-2 px-4 text-left border-b">Forward Calls To</th>
                <th className="py-2 px-4 text-left border-b">Tracking Numbers Limit</th>
                <th className="py-2 px-4 text-left border-b">Status</th>
              </tr>
            </thead>
            <tbody>
              {currentDynamicPools.map((item, index) => (
                <tr
                  key={item.poolName}
                  className="transition-colors duration-200"
                  onMouseEnter={() => setIsHovering(item.poolName)}
                  onMouseLeave={() => setIsHovering(null)}
                >
                  <td
                    className={`py-3 px-4 border-b ${isHovering === item.poolName
                      ? 'text-blue-500 underline cursor-pointer'
                      : 'cursor-default'
                      }`}
                  >
                    <a
                      href="#"
                      onClick={(e) => {
                        e.preventDefault(); 
                        handleRowClick(item.poolName);
                      }}
                    >
                      {item.poolName}
                    </a>
                  </td>
                  <td className="py-3 px-4 border-b">{item.swapNumber}</td>
                  <td className="py-3 px-4 border-b">{item.forwardCallsTo}</td>
                  <td className="py-3 px-4 border-b">{item.currentLimit}</td>
                  <td className="py-3 px-4 border-b">
                    <button
                      className={`${item.status ? 'bg-green-500' : 'bg-red-500 hover:bg-opacity-80'
                        } text-white px-4 py-2 rounded-full`}
                      onClick={() => handleStatusToggle(index)}
                    >
                      {item.status ? 'Active' : 'Inactive'}
                    </button>
                  </td>
                </tr>
              ))}

            </tbody>
          </table>
          <Pagination
            itemsPerPage={itemsPerPage}
            totalItems={filteredDynamicPools.length}
            currentPage={currentPage}
            paginate={paginate}
          />
        </div>
      )}

      {isCreatePoolOpen && (
        <CreatePool
          onClose={handleCreatePoolClose}
          onAcknowledgment={handleAcknowledgment}
          onCreatePool={onCreatePool}
        />
      )}
      {acknowledgment && (
        <Acknowledgment
          acknowledgment={acknowledgment}
          onClose={handleAcknowledgmentClose}
        />
      )}
    </div>
  );
};

export default DynamicPool;
