import React, { useState, useEffect } from 'react';
import { FaSyncAlt, FaSearch } from 'react-icons/fa';
import Pagination from './Pagination';
import CreateNumber from './CreateNumber';
import Acknowledgment from './Acknowledgment';
import './LoadingScreen.css';

const StaticNumberPage = () => {
  const [staticNumbers, setStaticNumbers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState('');
  const [isCreateNumberOpen, setIsCreateNumberOpen] = useState(false);
  const [acknowledgment, setAcknowledgment] = useState(null);
  const [isLoading, setIsLoading] = useState(true); 

  useEffect(() => {
    setIsLoading(true); 

    fetch('https://v2api.leadgen.pennysaver.in/get_phno')
      .then((response) => {
        if (!response.ok) {
          throw new Error('Failed to fetch static numbers');
        }
        return response.json();
      })
      .then((data) => {
        setStaticNumbers(data.staticNumbers);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log('Error fetching static numbers:', error);
        setIsLoading(false); 
      });
  }, []);

  const handleStatusToggle = (index) => {
    const updatedStaticNumbers = staticNumbers.map((item, i) => {
      if (i === index) {
        return {
          ...item,
          status: !item.status,
        };
      }
      return item;
    });
    setStaticNumbers(updatedStaticNumbers);
  };

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
    setCurrentPage(1);
  };

  const handleRefresh = () => {
    setIsLoading(true);

    fetch('https://api.leadgen.pennysaver.in/get_phno')
      .then((response) => {
        if (!response.ok) {
          throw new Error('Failed to fetch static numbers');
        }
        return response.json();
      })
      .then((data) => {
        setStaticNumbers(data.staticNumbers);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log('Error fetching static numbers:', error);
      });
  };

  const handleCreateNumberClick = () => {
    setIsCreateNumberOpen(true);
  };

  const handleCreateNumberClose = () => {
    setIsCreateNumberOpen(false);
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const filteredNumbers = staticNumbers.filter((item) =>
    item.phno_campaign.toLowerCase().includes(searchQuery.toLowerCase())
  );
  const currentItems = filteredNumbers.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleAcknowledgment = (acknowledgmentMessage) => {
    setAcknowledgment({ ...acknowledgmentMessage, sourcePage: 'staticNumberPage' });
  };

  const handleAcknowledgmentClose = () => {
    setAcknowledgment(null);
  };

  return (
    <div>
      <div className="flex justify-end items-center mb-4">
        <div className="relative flex items-center mr-2">
          <input
            type="text"
            value={searchQuery}
            onChange={handleSearch}
            placeholder="Campaign Name"
            className="px-4 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
          />
          <FaSearch className="absolute top-1/2 right-3 transform -translate-y-1/2 text-gray-500" />
        </div>
        <div className="flex">
          <button
            className="bg-gray-200 text-gray-600 rounded hover:bg-gray-300 p-2 focus:outline-none"
            onClick={handleRefresh}
          >
            <FaSyncAlt className="w-4 h-4" />
          </button>
          <button
            className="ml-2 bg-blue-500 text-white rounded hover:bg-blue-600 p-2 focus:outline-none"
            onClick={handleCreateNumberClick}
          >
            Create Number
          </button>
        </div>
      </div>
      <div className="overflow-x-auto">
        {isLoading ? (
          <div className="pinwheel">
            <div className="pinwheel__line"></div>
            <div className="pinwheel__line"></div>
            <div className="pinwheel__line"></div>
            <div className="pinwheel__line"></div>
            <div className="pinwheel__line"></div>
            <div className="pinwheel__line"></div>
          </div>
        ) : (
          <table className="min-w-full bg-white border border-gray-200">
            <thead>
              <tr>
                <th className="py-2 px-4 text-left border-b">Campaign Name</th>
                <th className="py-2 px-4 text-left border-b">Tracking Number</th>
                <th className="py-2 px-4 text-left border-b">Description</th>
                <th className="py-2 px-4 text-left border-b">Status</th>
              </tr>
            </thead>
            <tbody>
              {currentItems.map((item, index) => (
                <tr key={item.pk} className="hover:bg-gray-50">
                  <td className="py-3 px-4 border-b">{item.phno_campaign}</td>
                  <td className="py-3 px-4 border-b">{item.international}</td>
                  <td className="py-3 px-4 border-b">{item.phno_description}</td>
                  <td className="py-3 px-4 border-b">
                    <button
                      className={`${
                        item.phno_status ? 'bg-green-500' : 'bg-red-500 hover:bg-opacity-80'
                      } text-white px-4 py-2 rounded-full`}
                      onClick={() => handleStatusToggle(index)}
                    >
                      {item.phno_status ? 'Active' : 'Inactive'}
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
      {!isLoading && ( 
        <Pagination
          itemsPerPage={itemsPerPage}
          totalItems={filteredNumbers.length}
          currentPage={currentPage}
          paginate={paginate}
        />
      )}
      {isCreateNumberOpen && (
        <CreateNumber
          onClose={handleCreateNumberClose}
          setAcknowledgment={handleAcknowledgment}
        />
      )}
      {acknowledgment && (
        <Acknowledgment
          acknowledgment={acknowledgment}
          sourcePage="staticNumberPage"
          onClose={handleAcknowledgmentClose}
        />
      )}
    </div>
  );
};

export default StaticNumberPage;
