import React, { useState } from 'react';
import { Auth } from 'aws-amplify';
import { useHistory } from 'react-router-dom';

const CustomLogin = ({ onLogin }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [agreeToOTP, setAgreeToOTP] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const history = useHistory();

  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleCheckboxChange = () => {
    setAgreeToOTP(!agreeToOTP);
  };

  const handleLogin = async (e) => {
    e.preventDefault();

    // Validate input fields
    if (!username || !password) {
      setErrorMessage('Please enter both username and password.');
      return;
    }

    // Validate checkbox
    if (!agreeToOTP) {
      setErrorMessage('Please agree to receive OTP from AWS.');
      return;
    }

    try {
      // Your authentication logic here
      await Auth.signIn(username, password);
      onLogin();
      history.push('/Dashboard');
    } catch (error) {
      setErrorMessage(error.message);
    }
  };

  return (
    <div className="container mx-auto mt-8">
      <div className="max-w-md mx-auto">
        <h2 className="text-2xl font-bold mb-4">Sign In</h2>
        <form onSubmit={handleLogin} className="space-y-4">
          <div>
            <label htmlFor="username" className="block text-sm font-medium text-gray-700">
              Username
            </label>
            <input
              type="text"
              id="username"
              className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
              value={username}
              onChange={handleUsernameChange}
            />
          </div>
          <div>
            <label htmlFor="password" className="block text-sm font-medium text-gray-700">
              Password
            </label>
            <input
              type="password"
              id="password"
              className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
              value={password}
              onChange={handlePasswordChange}
            />
          </div>
          <div className="flex items-center">
            <input
              type="checkbox"
              id="agreeToOTP"
              checked={agreeToOTP}
              onChange={handleCheckboxChange}
              className="mr-2"
            />
            <label htmlFor="agreeToOTP" className="text-sm text-gray-700">
              I agree to receive OTP from AWS
            </label>
          </div>

          {errorMessage && <p className="text-red-500">{errorMessage}</p>}
          <button
            type="submit"
            className="mt-4 w-full inline-flex items-center justify-center px-6 py-3 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            Sign In
          </button>
        </form>
      </div>
    </div>
  );
};

export default CustomLogin;