import React, { useState } from 'react';
import Acknowledgment from './Acknowledgment';
import '../index.css'

const SetLimit = ({ onClose, dynamicPoolName }) => {
  const [limit, setLimit] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [acknowledgment, setAcknowledgment] = useState(null); 

  const handleSubmit = () => {
    setIsLoading(true);

    const payload = {
      limit: parseInt(limit),
      DynamicPoolName: dynamicPoolName,
    };

    fetch('https://v2api.leadgen.pennysaver.in/udpdate_phno_limit', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    })
      .then((response) => {
        
        return response.json();
      })
      .then((data) => {
        setIsLoading(false);

        if (data.message.includes('New phone number limit updated successfully')) {
          const acknowledgmentMessage = (
            <div>
              <p>{data.message}</p>
              <p className="limit-info">
                Current Limit: <span className="limit-value">{data.currentLimit}</span>
              </p>
              <p className="limit-info">
                Max Limit: <span className="limit-value">{data.maxLimit}</span>
              </p>
            </div>
          );
          setAcknowledgment({ status: 'success', message: acknowledgmentMessage });
        } else if (data.message.includes('Parameter limit or DynamicPoolName is not valid')) {
          setAcknowledgment({ status: 'warning', message: data.message });
        } else if (data.message.includes('Limit should be less than max limit')) {
          setAcknowledgment({ status: 'warning', message: data.message });
        } else if (data.message.includes('Limit can increase cannot decrease. To decrease limit please contact adminstrator.')) {
          setAcknowledgment({ status: 'warning', message: data.message });
        }

        setLimit('');
      })
      // .catch((error) => {
      //   setIsLoading(false);
      //   setAcknowledgment({ status: 'warning', message: 'Error occurred while setting the limit.' });
      // });
  };

  const handleAcknowledgmentClose = () => {
    setAcknowledgment(null);
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white rounded shadow p-8 w-96">
        <h2 className="text-lg font-bold mb-6">Set Limit</h2>
        <form>
          <div className="mb-4">
            <label htmlFor="poolName" className="block text-sm font-medium text-gray-700">
              Pool Name
            </label>
            <input
              type="text"
              id="poolName"
              className="mt-1 focus:ring focus:border-blue-500 block w-full shadow-sm sm:text-sm border border-gray-300 rounded-md"
              value={dynamicPoolName}
              readOnly
            />
          </div>

          <div className="mb-4">
            <label htmlFor="limit" className="block text-sm font-medium text-gray-700">
              Enter Limit
            </label>
            <input
              type="number"
              id="limit"
              className={`mt-1 focus:ring focus:border-blue-500 block w-full shadow-sm sm:text-sm border border-gray-300 rounded-md`}
              value={limit}
              onChange={(e) => {
                setLimit(e.target.value);
              }}
            />
          </div>
          <div className="flex justify-end">
            <button
              type="button"
              className="mr-2 py-2 px-4 bg-red-500 text-white rounded hover:bg-red-600 focus:outline-none focus:bg-red-600"
              onClick={onClose}
            >
              Cancel
            </button>
            <button
              type="button"
              className="py-2 px-4 bg-blue-500 text-white rounded hover:bg-blue-600 focus:outline-none focus:bg-blue-600"
              onClick={handleSubmit}
              disabled={isLoading}
            >
              {isLoading ? 'Setting...' : 'Set Limit'}
            </button>
          </div>
        </form>
        {acknowledgment && (
          <Acknowledgment acknowledgment={acknowledgment} onClose={handleAcknowledgmentClose} />
        )}
      </div>
    </div>
  );
};

export default SetLimit;
