import React from 'react';
import './QuickSightPage.css'

const QuicksightPage = () => {
  return (
    <div>
      <div className="quicksight-iframe-container">
        <iframe
          className="quicksight-iframe"
          width="100%" 
          height="720"
          src="https://ap-south-1.quicksight.aws.amazon.com/sn/embed/share/accounts/301838289846/dashboards/75747b0e-c938-43b4-a278-bc85c36f895c?directory_alias=athivatechqs">
        </iframe>
      </div>
    </div>
  );
}

export default QuicksightPage;


    
