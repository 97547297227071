import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FaSyncAlt, FaSearch, FaArrowLeft } from 'react-icons/fa';
import Pagination from './Pagination';
import SetLimit from './SetLimit';
import Acknowledgment from './Acknowledgment';

const DynamicPoolNumbers = ({ selectedPool }) => {
  const history = useHistory();
  const [poolNumbers, setPoolNumbers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [isLoading, setIsLoading] = useState(true);
  const [isSetLimitOpen, setIsSetLimitOpen] = useState(false);
  const [acknowledgment, setAcknowledgment] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');

  const handleGoBack = () => {
    window.location.reload();
  };

  const fetchPoolNumbers = async () => {
    try {
      const response = await fetch('https://v2api.leadgen.pennysaver.in/get_phno');
      const data = await response.json();
      if (data.dynamicNumbers[selectedPool]) {
        setPoolNumbers(data.dynamicNumbers[selectedPool].dynamicNumbers);
      }
      setIsLoading(false);
    } catch (error) {
      console.log('Error fetching pool numbers:', error);
      setIsLoading(false);
    }
  };


  useEffect(() => {
    
    localStorage.setItem('selectedPool', selectedPool);
    localStorage.setItem('currentPage', currentPage);

    
    if (poolNumbers.length === 0) {
      fetchPoolNumbers();
    }
  }, [selectedPool, currentPage, poolNumbers]);


  const filteredPoolNumbers = poolNumbers.filter(number =>
    number.e164.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Sort the numbers: Assigned to session ID first, then by status
  const sortedPoolNumbers = [...filteredPoolNumbers].sort((a, b) => {
    if (a.session_id && !b.session_id) {
      return -1;
    } else if (!a.session_id && b.session_id) {
      return 1;
    } else if (a.phno_status && !b.phno_status) {
      return -1;
    } else if (!a.phno_status && b.phno_status) {
      return 1;
    } else {
      return 0;
    }
  });

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentPoolNumbers = sortedPoolNumbers.slice(indexOfFirstItem, indexOfLastItem);

  
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
    setCurrentPage(1);
  };

  const handleSetLimitClick = () => {
    setIsSetLimitOpen(true);
  };

  const handleRefresh = async (event) => {
    event.preventDefault(); 
    setIsLoading(true);
    try {
      await fetchPoolNumbers();
    } finally {
      setIsLoading(false);
    }
  }

  const handleSetLimitClose = () => {
    setIsSetLimitOpen(false);
  };

  
  const sortedNumbers = poolNumbers.slice().sort((a, b) => {
    
    if (a.phno_status && !b.phno_status) {
      return -1;
    } else if (!a.phno_status && b.phno_status) {
      return 1;
    } else {
      return 0;
    }
  });
  const handleAcknowledgmentClose = () => {
    setAcknowledgment(null);
  };

  const handleStatusToggle = (index) => {
    
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="flex justify-between items-center mb-4">
        <div>
          <button
            className="mr-2 bg-blue-500 text-white rounded hover:bg-blue-600 p-2 focus:outline-none"
            onClick={handleGoBack}
          >
            <FaArrowLeft className="w-4 h-4" />
          </button>
        </div>
        <div className="flex items-center">
          <div className="relative">
            <input
              type="text"
              value={searchQuery}
              onChange={handleSearch}
              placeholder="Search Number"
              className="px-4 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
            />
            <FaSearch className="absolute top-1/2 right-3 transform -translate-y-1/2 text-gray-500" />
          </div>
          <button
            className="ml-4 bg-gray-200 text-gray-600 rounded hover:bg-gray-300 p-2 focus:outline-none"
            onClick={handleRefresh}
          >
            <FaSyncAlt className="w-4 h-4" />
          </button>
          <button
            className="ml-4 bg-blue-500 text-white rounded hover:bg-blue-600 p-2 focus:outline-none"
            onClick={handleSetLimitClick}
          >
            Set Limit
          </button>
        </div>
      </div>

      {isLoading ? (
        <div className="pinwheel">

          <div className="pinwheel__line"></div>
          <div className="pinwheel__line"></div>
          <div className="pinwheel__line"></div>
          <div className="pinwheel__line"></div>
          <div className="pinwheel__line"></div>
          <div className="pinwheel__line"></div>
        </div>
      ) : (
        <div>

          {poolNumbers.length === 0 ? (
            <p>No numbers found for the selected pool.</p>
          ) : (
            <div>
              <table className="min-w-full bg-white border border-gray-200">
                <thead>
                  <tr>
                    <th className="py-2 px-4 text-left border-b">Tracking Number</th>
                    <th className="py-2 px-4 text-left border-b">Status</th>
                    <th className="py-2 px-4 text-left border-b">Device Type</th>
                    <th className="py-2 px-4 text-left border-b">City-State</th>
                    <th className="py-2 px-4 text-left border-b">Referrer</th>
                    {/* <th className="py-2 px-4 text-left border-b">Swap Number</th> */}
                  </tr>
                </thead>
                <tbody>
                  {currentPoolNumbers.map((number) => (
                    <tr key={number.pk} className="hover:bg-gray-50">
                      <td className="py-3 px-4 border-b">{number.e164}</td>
                      <td className="py-3 px-4 border-b">
                        <button
                          className={`${number.session_id ? 'bg-yellow-500' : number.phno_status ? 'bg-green-500' : 'bg-red-500 hover:bg-opacity-80'
                            } text-white px-4 py-2 rounded-full`}
                          disabled
                        >
                          {number.session_id ? 'In-use' : number.phno_status ? 'Available' : 'Inactive'}
                        </button>
                      </td>
                      <td className="py-3 px-4 border-b">{number.session_id ? number.session_item.device_information : '-'}</td>
                      <td className="py-3 px-4 border-b">{number.session_id ? `${number.session_item.location_details.city}-${number.session_item.location_details.region}` : '-'}</td>
                      <td className="py-3 px-4 border-b">{number.session_id ? number.session_item.ref : '-'}</td>
                      {/* <td className="py-3 px-4 border-b">{number.session_id ? number.session_item.swaps[0] : '-'}</td> */}
                    </tr>
                  ))}
                </tbody>
              </table>

              <Pagination
                itemsPerPage={itemsPerPage}
                totalItems={sortedPoolNumbers.length}
                currentPage={currentPage}
                paginate={paginate}
              />
            </div>
          )}
        </div>
      )}
      {acknowledgment && (
        <Acknowledgment acknowledgment={acknowledgment} onClose={handleAcknowledgmentClose} />
      )}

      {isSetLimitOpen && (
        <SetLimit
          onClose={handleSetLimitClose}
          setAcknowledgment={setAcknowledgment}
          dynamicPoolName={selectedPool} 
        />
      )}

    </div>
  );
};

export default DynamicPoolNumbers;