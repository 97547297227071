import React, { useState, useEffect } from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import Dashboard from './Components/Dashboard';
import CustomLogin from './Components/CustomLogin';
import QuickSightDashboard from './Components/QuickSightPage';

const App = () => {
  const [authenticated, setAuthenticated] = useState(false);

  useEffect(() => {
    const isAuthenticated = localStorage.getItem('authenticated');
    if (isAuthenticated) {
      setAuthenticated(true);
    }
  }, []);

  const handleLogin = () => {
    setAuthenticated(true); 
    localStorage.setItem('authenticated', 'true');
  };

  const handleLogout = () => {
    setAuthenticated(false); 
    localStorage.removeItem('authenticated');
  };

  return (
    <div className="App">
      <BrowserRouter>
        <Switch>
          <Route
            exact
            path="/"
            render={(props) =>
              authenticated ? (
                <Redirect to="/dashboard" />
              ) : (
                <CustomLogin {...props} onLogin={handleLogin} />
              )
            }
          />
          <Route
            exact
            path="/dashboard"
            render={(props) =>
              authenticated ? (
                <Dashboard {...props} onLogout={handleLogout} />
              ) : (
                <Redirect to="/" />
              )
            }
          />
          <Route exact path="/analytics" component={QuickSightDashboard} />
        </Switch>
      </BrowserRouter>
    </div>
  );
};

export default App;
