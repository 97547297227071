import React, { useState } from 'react';
import Acknowledgment from './Acknowledgment';

const CreateNumber = ({ onClose }) => {
  const [phnoCampaign, setPhnoCampaign] = useState('');
  const [phnoDescription, setPhnoDescription] = useState('');
  const [phnoType, setPhnoType] = useState('static');
  const [isLoading, setIsLoading] = useState(false);
  const [acknowledgment, setAcknowledgment] = useState(null);
  const [isCreateNumberVisible, setIsCreateNumberVisible] = useState(true);

  const handleSubmit = () => {
    setIsLoading(true);

    const payload = {
      phno_campaign: phnoCampaign,
      phno_description: phnoDescription,
      phno_type: phnoType,
    };

    fetch('https://v2api.leadgen.pennysaver.in/acquire_phno', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setIsLoading(false);
        if (data.acquiredPhnoList.length === 0) {
          setAcknowledgment({ status: 'warning', message: 'Unable to provision the Number' });
        } else {
          setIsCreateNumberVisible(false)
          setAcknowledgment({
            status: 'success',
            message: `Number created: ${data.acquiredPhnoList[0]}`,
          });
        }
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
        setAcknowledgment({ status: 'warning', message: 'Error occurred while creating the number.' });
      });
  };

  const handleAcknowledgmentClose = () => {
    setAcknowledgment(null);
    setTimeout(() => {
      window.location.reload();
    }, 18000);
  };

  const renderCampaignInput = () => {
    if (phnoType === 'static') {
      return (
        <div className="mb-4">
          <label htmlFor="phnoCampaign" className="block text-sm font-medium text-gray-700">
            Campaign
          </label>
          <input
            type="text"
            id="phnoCampaign"
            className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
            value={phnoCampaign}
            onChange={(e) => setPhnoCampaign(e.target.value)}
          />
        </div>
      );
    }
    return null;
  };

  const renderDescriptionInput = () => {
    if (phnoType === 'static') {
      return (
        <div className="mb-4">
          <label htmlFor="phnoDescription" className="block text-sm font-medium text-gray-700">
            Description
          </label>
          <textarea
            id="phnoDescription"
            className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
            rows="3"
            value={phnoDescription}
            onChange={(e) => setPhnoDescription(e.target.value)}
          ></textarea>
        </div>
      );
    }
    return null;
  };

  return (
    <>
      {isCreateNumberVisible && ( 
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white rounded shadow p-8 w-96">
            <h2 className="text-lg font-bold mb-6">Create Number</h2>
            <form>
              <div className="mb-4">
                <label htmlFor="phnoType" className="block text-sm font-medium text-gray-700">
                  Type: Static
                </label>
              </div>
              {renderCampaignInput()}
              {renderDescriptionInput()}
              <div className="flex justify-end">
                <button
                  type="button"
                  className="mr-2 py-2 px-4 bg-red-500 text-white rounded hover:bg-red-600 focus:outline-none focus:bg-red-600"
                  onClick={onClose}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="py-2 px-4 bg-blue-500 text-white rounded hover:bg-blue-600 focus:outline-none focus:bg-blue-600"
                  onClick={handleSubmit}
                  disabled={isLoading}
                >
                  {isLoading ? 'Creating...' : 'Create'}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
      {acknowledgment && (
        <Acknowledgment acknowledgment={acknowledgment} onClose={handleAcknowledgmentClose} />
      )}
    </>
  );
};

export default CreateNumber;
