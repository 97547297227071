import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import {
  Amplify
} from 'aws-amplify';

// import awsconfig from './aws-exports';
import './index.css';
import App from './App';

// Amplify.configure(awsconfig);

ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById('root')
);
